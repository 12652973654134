<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog )}">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
    <MDBRow>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.PROMONAME')" :placeholder="$t('COMMON.PROMONAME')" v-model="where.name" />
      </MDBCol>
      <MDBCol md="2">
        <select class="form-select" v-model="where.status">
          <option value="">優惠狀態</option>
          <option value="1">{{$t("PROMO.STATUS_1")}}</option>
          <option value="0">{{$t("PROMO.STATUS_0")}}</option>
        </select>
      </MDBCol>
      <MDBCol class="mb-2 text-end">
        <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
        <MDBBtn color="info" @click="add_dialog = true">{{ $t("PROMO.ADD-ITEM") }}</MDBBtn>  
      </MDBCol>
    </MDBRow>
		<DataTable
			:header="headers"
			:tabledata="desserts"
			v-model:total="total"
			v-model:options="options"
			v-model:loading="loading"
		>
			<!-- 時間轉換 -->
      <template v-slot:created_at="{ item }">
        {{ Common.TimeFormat(item.created_at) }}
      </template>
      <template v-slot:next_at="{ item }">
        {{ ((!!item.payment_at)?NextDate(item.payment_at,1):"") }}
      </template>
      <template v-slot:payment_at="{ item }">
        {{ ((!!item.payment_at)?NextDate(item.payment_at,item.payment_able):"") }}
      </template>
      <template v-slot:amount="{ item }">
        {{ `${(item.amount * 100)} %` }}
      </template>
      <template v-slot:status="{ item }">
        <div class="badge  text-wrap" :class="{ 'bg-danger': (item.status == 0),'bg-success': (item.status == 1) }">
          {{ $t("PROMO.STATUS_" + item.status) }}
        </div>
      </template>
      <!-- 操作template -->
      <!-- <template v-slot:uuid="{ item }">
        <a @click="EditUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('PRODUCT-LIST.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
          <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
        </a>
        <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('PRODUCT-LIST.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
          <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
        </a>
      </template> -->
      <template v-slot:edit="{ item }">
        <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
          <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
      <template v-slot:uuid="{ item }">
        <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
          <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
		</DataTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
  <PromoForm v-model:model="add_dialog" action="insert"></PromoForm>
  <PromoForm v-model:model="edit_dialog" action="update" v-model:uuid="EditUUid"></PromoForm>
</template>

<script>
import { MDBContainer, MDBCard, MDBCardBody, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
import PromoForm from '@/components/Form/Promo/PromoForm';

export default {
  name: "Promo",
  components: {
		DataTable,
		MDBContainer,
		Breadcrumbs,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBIcon,
    MDBBtn,
    MDBRow,
    MDBCol,
    PromoForm,
    MDBInput
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(false);
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const DetailUUid = ref("");
    const desserts = ref([]);
    const EditUUid = ref("");

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: ['desc'],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });

    const where = reactive({
      name: "",
      status: "",
    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",width: "50px" ,class: "text-center"
      },
      { text: i18n.t("COMMON.PROMONAME"), value: "name" },
      { text: i18n.t("COMMON.PROMOCODE"), value: "code" },
      { text: i18n.t("COMMON.PROMOSTARTDATE"), value: "start" },
      { text: i18n.t("COMMON.PROMOENDDATE"), value: "end" },
      { text: i18n.t("COMMON.PROMOAMOUNT"), value: "amount" },
      { text: i18n.t("COMMON.PROMOSTATUS"), value: "status" },
      { text: i18n.t("COMMON.CREATETIME"), value: "created_at" },
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,width: "50px" ,class: "text-center"},
      { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,width: "50px" ,class: "text-center"}
    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/Promo",
          {
            params: Object.assign(CommonService.SortHandleObject(options),where)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const OrderAction = function(order,action) {
      ApiService.post("/Admin/v1/Subscription", { 'order':order,'action':action }).then(response => {
        if (response.status == 200) {
          CommonService.AxiosHandle(response);
          GetTable();
        }
      });
    };

    const NextDate = function(date,month) {
      let d = new Date(date);
      d.setMonth(d.getMonth()+month);
      return d.toLocaleString();
    }

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Promo/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

		return {
      PageLoading,
      add_dialog,
      edit_dialog,
      loading,
      total,
      Common,
      options,
      headers,
      desserts,
      DetailUUid,
      GetTable,
      NextDate,
      OrderAction,
      EditUUid,
      DeleteAction,
      where
    };
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
  },
  mounted() {
    this.GetTable();
  },
  provide () {
    return {
      GetTable: this.GetTable,
    }
  }
};
</script>