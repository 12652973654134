<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(Action == 'insert')">{{ $t("PROMO.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(Action == 'update')">{{ $t("PROMO.EDIT-ITEM") }}</MDBCardHeader>
        <MDBRow class="mb-3" >
          <MDBCol md="12">
            <MDBRow class="g-3">
              <!-- 輪播圖 -->
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.PROMONAME') }}<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" :placeholder="$t('COMMON.PROMONAME')" v-model="Form.name.value">
                  <span class="text-danger">{{ nameError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12" v-if="action == 'insert'">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.PROMOCODE') }}<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" :placeholder="$t('COMMON.PROMOCODE')" v-model="Form.code.value">
                  <span class="text-danger">{{ codeError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12" v-if="action == 'insert'">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.PROMOSTARTDATE') }}<span class="text-danger">*</span></label>
                  <MDBDatepicker :label="$t('COMMON.PROMOSTARTDATE')" format="YYYY-MM-DD" v-model="Form.start.value"></MDBDatepicker>
                  <span class="text-danger">{{ startError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12" v-if="Model">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.PROMOENDDATE') }}</label>
                  <MDBDatepicker :label="$t('COMMON.PROMOENDDATE')" format="YYYY-MM-DD" v-model="Form.end.value"></MDBDatepicker>
                  <span class="text-danger">{{ endError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.PROMOAMOUNT') }}(%)<span class="text-danger">*</span></label>
                  <input type="number" class="form-control" :placeholder="$t('COMMON.PROMOAMOUNT')" v-model="Form.amount.value">
                  <span class="text-danger">{{ amountError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">總次數<span class="text-danger">*</span></label>
                  <input type="number" class="form-control" placeholder="總次數" v-model="Form.total.value">
                  <span class="text-danger">{{ totalError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">會員使用次數<span class="text-danger">不填寫則不限次數</span></label>
                  <input type="number" class="form-control" placeholder="會員使用次數" v-model="Form.limit.value">
                  <span class="text-danger">{{ limitError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12" v-if="action == 'update'">
                <div class="mb-3">
                  <div>
                    <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.PROMOSTATUS') }}<span class="text-danger">*</span></label>  
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-bind:id="`${Action}-status-1`" value="1" v-model="Form.status.value">
                    <label class="form-check-label" v-bind:for="`${Action}-status-1`">
                      {{ $t('COMMON.PUTON') }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-bind:id="`${Action}-status-0`" value="0" v-model="Form.status.value">
                    <label class="form-check-label" v-bind:for="`${Action}-status-0`">
                      {{ $t('COMMON.PUTOFF') }}
                    </label>
                  </div>
                  <span class="text-danger">{{ statusError }}</span>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBBtn color="primary" type="submit"  v-if="action == 'insert'" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn color="primary" type="submit" v-if="action == 'update'" :disabled="SubmitLoading">
          {{ $t("COMMON.SAVE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="Close">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .image-modal {
    height: 85vh;
    overflow-y: auto;
  }
  .edit-slim img {
    width: 100%;
  }
</style>

<script>
import { MDBRow, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBDatepicker } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';

export default {
  name: "PromoForm",
  components: {
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBDatepicker
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    model: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.model,
      set: val => emit("update:model", val)
    });

    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });

    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const GetTable = inject("GetTable");
    const i18n = useI18n();
    const SubmitLoading = ref(false);

    const basicSchema = {
      name: yup.string().required().label(i18n.t("COMMON.PROMONAME")),
      end: yup.string().label(i18n.t("COMMON.PROMOENDDATE")),
      amount: yup.number().required().max(100).label(i18n.t("COMMON.PROMOAMOUNT")),
      total: yup.number().required().label("總次數"),
      limit: yup.number().label("會員使用次數"),
    };

    let ExtendSchema = {};
    if(Action.value == "insert") {
      ExtendSchema = {
        start: yup.string().required().label(i18n.t("COMMON.PROMOSTARTDATE")),
        code: yup.string().required().label(i18n.t("COMMON.PROMOCODE")),
      };  
    }else{
      ExtendSchema = {
        status: yup.number().required().label(i18n.t("COMMON.PROMOSTATUS")),
      };
    }
    
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: FormSchema,
      initialValues: {
        code: "",
        name: "",
        start: "",
        end: "",
        amount: 0,
        total: 0,
        limit: 0,
      }
    });

    const { value: code, errorMessage: codeError } = useField('code');
    const { value: name, errorMessage: nameError } = useField('name');
    const { value: start, errorMessage: startError } = useField('start');
    const { value: end, errorMessage: endError } = useField('end');
    const { value: amount, errorMessage: amountError } = useField('amount');
    const { value: status, errorMessage: statusError } = useField('status');
    const { value: total, errorMessage: totalError } = useField('total');
    const { value: limit, errorMessage: limitError } = useField('limit');

    let Submit;

    if(Action.value == "insert") {
      Submit = handleSubmit(values => {
        let form = CommonService.MakeForm(values);
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/Promo", form).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            GetTable();
          }
        });
      });
    }else{
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.update("/Admin/v1/Promo",props.uuid ,values).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            FormUUid.value = "";
            GetTable();
          }
        });
      });
    }

    const Form = {
      code,
      name,
      start,
      end,
      amount,
      status,
      total,
      limit,
    };

    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
    };

    return {
      Action,
      Model,
      SubmitLoading,
      Form,
      codeError,
      nameError,
      startError,
      endError,
      amountError,
      statusError,
      totalError,
      limitError,
      Submit,
      resetForm,
      FormUUid,
      Close,
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    },
  },
  methods: {
    GetDetail() {
      this.resetForm();
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Promo", this.FormUUid).then(response => {
            if (response.status == 200) {
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "amount":
                      this.Form.amount.value = response.data.data.amount * 100;
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    },
  }
};
</script>